import Image from "next/image";
import Navbar from "../components/Navbar/Navbar";
import SectionOne from "../components/Section/SectionOne";
import WaIcon from "../assets/icons/wa-svg-icon.svg"
import WaIconFill from "../assets/icons/wa-icon.png"
import Footer from "../components/Footer/Footer";
import CloseIcon from '../assets/icons/close-icon.svg'
import { useState, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SectionTwo from "../components/Section/SectionTwo";
import SectionThree from "../components/Section/SectionThree";
import SectionFour from "../components/Section/SectionFour";
import SuccessIcon from '../assets/icons/success.svg'
import { NextSeo } from "next-seo";
import seo from '../seo.config'
import { Element } from "react-scroll";
import Head from "next/head";

const Home = () => {
  const modalRef = useRef(null)
  const modalSuccessRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [successOpen, setSuccessOpen] = useState(false)

  const closeModalSucess = () => {
    setSuccessOpen(false)
  }

  const openModalSuccess = () => {
    setSuccessOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  return (
    <div className="font-roboto text-w-text">
      <Head>
        <link rel="workay_icon" href="/favicon.ico" />
      </Head>
      <NextSeo
        defaultTitle={seo.title}
        description={seo.description}
        canonical="https://www.workay.id"
        openGraph={{
          type: "website",
          site_name: "Workay",
          title: seo.title,
          description: seo.description,
          images: [
            {
              url: seo.image,
            },
          ],
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />
      <Navbar />
      {isOpen || successOpen ? null : (
        <div
          onClick={openModal}
          className="fixed h-[40x] w-[40px] transition-all hover:scale-110 active:scale-100 right-[26px] md:right-[100px] lg:right-[150px] bottom-10 md:bottom-20 z-[8]"
        >
          <Image layout="responsive" src={WaIconFill} alt="_wa-icon" />
        </div>
      )}
      <Element name="section-1">
        <SectionOne />
      </Element>
      <Element name="section-2">
        <SectionTwo />
      </Element>
      <Element name="section-3">
        <SectionThree />
      </Element>
      <Element name="section-4">
        <SectionFour openModal={openModalSuccess} />
      </Element>
      <Footer openModal={openModal} />

      {/* Whatsapp modal */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          initialFocus={modalRef}
          as="div"
          className="relative z-10"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-5 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full py-[60px] max-w-3xl transform overflow-hidden rounded-[15px] bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex gap-x-[24px] flex-col md:flex-row justify-center items-center">
                    <div
                      onClick={closeModal}
                      className="w-[42px] flex hover:scale-110 active:scale-100 justify-center items-center h-[42px] absolute top-5 right-5"
                    >
                      <Image src={CloseIcon} alt="close-icon" />
                    </div>
                    <div className="w-[132px] h-[132px] mb-[35px] md:md-0 md:w-[211px] md:h-[211px]">
                      <Image src={WaIcon} alt="_wa-icon" />
                    </div>
                    <div className="flex flex-col justify-center items-center">
                      <div className="font-roboto text-center text-w-text w-[262px] mb-5">
                        <p>
                          Kamu akan terhubung dengan tim Layanan Pelanggan
                          Workay
                        </p>
                      </div>
                      <div>
                        <a
                          href="https://wa.me/+6281219987890"
                          target="_blank"
                          rel="noreferrer"
                          ref={modalRef}
                          className="focus:outline-none transition-all hover:scale-110 active:scale-100 bg-w-green-200 rounded-[10px] py-[11px] px-[100px] text-white"
                        >
                          Lanjut
                        </a>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Form success modal */}
      <Transition appear show={successOpen} as={Fragment}>
        <Dialog
          initialFocus={modalSuccessRef}
          as="div"
          className="relative z-10"
          onClose={closeModalSucess}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-5 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full py-[60px] max-w-3xl transform overflow-hidden rounded-[15px] bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex gap-x-[60px] flex-col md:flex-row justify-center items-center">
                    <div
                      onClick={closeModalSucess}
                      className="w-[42px] h-[42px] flex hover:scale-110 active:scale-100 justify-center items-center  absolute top-5 right-5"
                    >
                      <Image src={CloseIcon} alt="close-icon" />
                    </div>
                    <div className="w-[157px] h-[157px] mb-[35px] md:md-0 md:w-[211px] md:h-[211px]">
                      <Image src={SuccessIcon} alt="_wa-icon" />
                    </div>
                    <div className="flex flex-col justify-center items-center">
                      <div className="text-w-green-200 text-center leading-[50px] text-[48px] font-bold">
                        <p>Data Terkirim</p>
                      </div>
                      <div
                        ref={modalSuccessRef}
                        className="font-roboto px-[30px] md:px-[0] text-center mt-3 text-w-text w-full md:w-[262px] "
                      >
                        <p>
                          Silahkan tunggu, tim representatif kami akan
                          menghubungi Anda
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default Home;