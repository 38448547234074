import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import Swiper1 from '../../assets/images/swiper-1.png'
import Swiper2 from '../../assets/images/swiper-2.png'
import Swiper3 from '../../assets/images/swiper-3.png'
import { Link as LinkScroll } from 'react-scroll'

import 'swiper/css';
import 'swiper/css/pagination';

import { Autoplay, Pagination } from "swiper";

const SectionOne = () => {
  return (
    <>
      <div className="pb-[50px] overflow-hidden bg-w-green-100 px-[0px] lg:px-[150px]  py-[20px]">
        <div className="container mx-auto flex flex-col justify-center items-center lg:flex-row lg:justify-between gap-x-[50px]">
          <div className="w-[540px] sm:w-[428px] -mt-[70px] sm:-mt-[0px] block lg:hidden">
            <Swiper pagination={{ clickable: true }} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[Pagination, Autoplay]} className="mySwiper" >
              <SwiperSlide className="">
                <div className="sm:pb-[27px]">
                  <Image layout="responsive" priority src={Swiper1} alt="_swiper_1" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="sm:pb-[27px]">
                  <Image layout="responsive" loading="lazy" src={Swiper2} alt="_swiper_1" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="sm:pb-[27px]">
                  <Image layout="responsive" loading="lazy" src={Swiper3} alt="_swiper_1" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="flex sm:mt-5 lg:mt-0 px-[56px] lg:px-[0px] flex-col items-center lg:items-start justify-center">
            <div className="font-night text-w-green-300 text-[50px] text-center lg:text-left">
              <p>Wellness that works!</p>
            </div>
            <div className="pt-[15px] pb-[15px] text-center lg:text-left">
              <h1 className="pb-[28px]">Pertahankan talenta terbaik, tingkatkan performa dan produktivitas bisnis Anda. Workay makes everything okay!</h1>
              <p>Solusi kesejahteraan karyawan:</p>
            </div>
            <div>
              <LinkScroll href="#section-4" offset={-80} spy smooth to="section-4">
                <button className="transition-all hover:scale-110 active:scale-100 bg-w-green-200 rounded-[10px] py-[11px] px-[25px] text-white">Jadwalkan Demo Sekarang</button>
              </LinkScroll>
            </div>
          </div>
          <div className="w-[560px] hidden lg:block">
            <Swiper pagination={{ clickable: true }} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[Pagination, Autoplay]} className="mySwiper" >
              <SwiperSlide className="">
                <div className="pb-[27px]">
                  <Image layout="responsive" priority src={Swiper1} alt="_swiper_1" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pb-[27px]">
                  <Image layout="responsive" loading="lazy" src={Swiper2} alt="_swiper_1" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pb-[27px]">
                  <Image layout="responsive" loading="lazy" src={Swiper3} alt="_swiper_1" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionOne;