import { Listbox, Transition } from "@headlessui/react";
import axios from "axios";
import Image from "next/image";
import { Fragment, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectorIcon from '../../assets/icons/selector-icon.svg'
import Reaptcha from "reaptcha"

const dataTipe = [
  "Saya ingin bertanya tentang Workay untuk perusahaan saya/orang lain",
  "Saya ingin bermitra dengan Workay sebagai vendor rewards management"
]

const SiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY

const getWindowSize = () => {
  const {innerWidth, innerHeight} = typeof window !== "undefined" && window
  return {innerWidth, innerHeight}
}

const SectionFour = ({ openModal }) => {
  const [selected, setSelected] = useState()
  const { control, handleSubmit, reset, register, formState: { errors } } = useForm();
  const refCaptcha = useRef()
  const [captcha, setCaptcha] = useState('')
  const [recaptchaNeeded, setRecaptchaNeeded] = useState(false);

  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState({
    status: false,
    message: ''
  })

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    };
  }, []);

  const handleOnSubmit = async (e) => {
    if (captcha) {
      setLoading(true)
      setErr({
        status: false,
        message: ''
      })
      const dataBody = {
        captcha: captcha,
        data: e
      }
      await axios.post("/api/v1/subscriptions", dataBody, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(res => {
        if (res.data.status) {
          openModal()
          setLoading(false)
          setSelected(null)
          reset()
          refCaptcha.current.reset()
          setCaptcha("")
        }
      }).catch(err => {
        setErr({
          status: true,
          message: 'Ada kesalahan, coba lagi'
        })
        setLoading(false)
        setCaptcha("")
        refCaptcha.current.reset()
      })

    } else {
      setErr({
        status: true,
        message: 'Captcha belum dicentang'
      })
    }

  }

  return (
    <div className="pt-[65px] small-screen:pt-[131px] md:pt-[162px]">
      <div className="container mx-auto flex gap-y-[56px] md:pl-[160px] md:pr-[150px] pb-[130px] flex-col lg:flex-row gap-x-[100px] xl:gap-x-[160px] justify-between items-center">
        <div className="flex flex-col text-center lg:text-left">
          <div>
            <p className="px-[20px] md:p-0 font-bold text-[48px] text-w-green-200">Bersama Workay,</p>
          </div>
          <div className="px-[45px] md:p-0 font-night text-[24px] text-outline-2">
            <h4>Wujudkan Kesejahteraan Karyawan & Pertahankan talenta terbaik Anda!</h4>
          </div>
          <div className="px-[57px] md:p-0 mt-2">
            <h5>Siap menjadikan financial wellness bagian dari perusahaan Anda? Isi formulir di samping/di bawah, tim representatif Workay akan segera menghubungi Anda.</h5>
          </div>
        </div>
        <div className="flex flex-col w-full px-[38px] md:p-0">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="flex flex-col w-full">
              <div className="mb-[30px]">
                <label className="block text-w-green-200 font-bold mb-1">
                  Nama
                </label>
                <input {...register('name', {
                  required: true,
                  onChange: () => {
                    setErr({
                      status: false,
                      message: ''
                    })
                    setRecaptchaNeeded(true)
                  }
                })} className={`${errors.name ? "border-red-400 bg-red-100" : "border-w-green-200 bg-w-green-100"} appearance-none border rounded-[10px] w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline`} type="text" placeholder="Masukan nama" />

              </div>
              <div className="mb-[30px]">
                <label className="block text-w-green-200 font-bold mb-1">
                  Email
                </label>
                <input {...register('email', {
                  required: true,
                  pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  onChange: () => {
                    setErr({
                      status: false,
                      message: ''
                    })
                  }
                })} className={`${errors.email ? "border-red-400 bg-red-100" : "border-w-green-200 bg-w-green-100"} appearance-none border rounded-[10px] w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline`} type="text" placeholder="Masukan email" />
              </div>
              <div className="mb-[30px]">
                <label className="block text-w-green-200 font-bold mb-1">
                  Nomor Whatsapp
                </label>
                <input {...register('number_phone', {
                  required: true,
                  valueAsNumber: false,
                  onChange: () => {
                    setErr({
                      status: false,
                      message: ''
                    })
                  }
                })} className={`${errors.number_phone ? "border-red-400 bg-red-100" : "border-w-green-200 bg-w-green-100"} appearance-none border rounded-[10px] w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline`} type="number" placeholder="Masukan nomor whatsapp" />
              </div>
              <div className="mb-[30px]">
                <Controller
                  control={control}
                  defaultValue=""
                  name="tipe"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange } }) => (
                    <Listbox value={selected} onChange={(e) => {
                      onChange(e)
                      setSelected(e)
                      setErr({
                        status: false,
                        message: ''
                      })
                    }}>
                      {({ open }) => (
                        <div className="relative mt-1">
                          <Listbox.Label className="block text-w-green-200 font-bold mb-1">
                            Tipe
                          </Listbox.Label>
                          <Listbox.Button className={`relative overflow-hidden justify-between items-center w-full text-left cursor-default appearance-none ${open && 'border-b-0'} border ${errors.tipe ? "border-red-400 bg-red-100" : "border-w-green-200 bg-w-green-100"} rounded-[10px] transition-all ${open && "rounded-b-none"} leading-tight focus:outline-none focus:shadow-outline`}>
                            <span className={`block py-3 px-4 pr-[50px] ${selected ? "" : 'opacity-[0.6]'}`}>{selected ? selected : "Pilih pertanyaan"}</span>
                            <span className="pointer-events-none  absolute w-[45px] h-full top-0 right-0 ">
                              <div className={`${errors.tipe ? "bg-red-400 " : "bg-w-green-200"} flex justify-center items-center w-full h-full`}>
                                <Image className={`transition-all ${open && "rotate-180"}`} layout="fixed" src={SelectorIcon} alt="_selector-icon" />
                              </div>
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute max-h-60 w-full overflow-auto rounded-b-[10px] bg-w-green-100 border border-t-0 border-w-green-200 z-[9999]">
                              {dataTipe.map((tipe, personIdx) => (
                                <Listbox.Option
                                  key={personIdx}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-3 pr-[50px] ${active && 'bg-w-green-200 text-white'}`
                                  }
                                  value={tipe}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block `}
                                      >
                                        {tipe}
                                      </span>
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </Listbox>
                  )} />
                {(errors.name || errors.email || errors.number_phone || errors.tipe || err.status) && <p className="block text-red-400 font-bold mt-5">{err.message ? err.message : "Informasi belum lengkap"}</p>}
              </div>
              <div className={`${recaptchaNeeded && 'mb-[20px]'}`}>
                {recaptchaNeeded && <Reaptcha
                  id={"captcha"}
                  ref={refCaptcha}
                  sitekey={SiteKey}
                  size={windowSize.innerWidth < 400 ? "compact" : "normal"}
                  onVerify={(e) => {
                    setCaptcha(e)
                    setErr({
                      status: false,
                      message: ''
                    })
                  }}
                  onExpire={() => setCaptcha('')}
                />}
              </div>
              <div className="flex flex-row items-center justify-center lg:items-start lg:justify-start">
                <button type="submit" disabled={loading || captcha.length == 0} className="disabled:bg-w-green-200-disable disabled:text-gray-400 transition-all flex flex-row justify-between items-center hover:scale-110 active:scale-100 bg-w-green-200 rounded-[10px] py-[11px] px-[60px] text-white">
                  {loading && <svg aria-hidden="true" className="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-w-green-100" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>}
                  {loading ? "loading..." : "Kirim"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default SectionFour;