import Image from 'next/image';
import SliceImage from '../../assets/images/slice-image.png'
import SectionImage2 from '../../assets/images/section-1.png'
import SectionImage3 from '../../assets/images/section-2.png'

const SectionTwo = () => {
  return (
    <div className="pt-[70px]">
      <div className='container mx-auto'>
        <div className="flex flex-col items-center justify-center">
          <div className="text-center md:px-[0px]">
            <div>
              <p className="text-[48px] font-bold text-w-green-200">Mengapa pilih Workay?</p>
            </div>
            <div className="w-full md:w-[657px] mt-[30px]">
              <h2>Perfoma karyawan berpengaruh sangat tinggi terhadap produktivitas perusahaan. Bersama Workay, hapuskan stres finansial karyawan Anda!</h2>
            </div>
          </div>
          <div className="px-[30px] lg:px-[150px] w-full mt-[65px]">
            <div className="flex flex-col items-center justify-center rounded-b-[20px] lg:rounded-bl-[0px] lg:rounded-r-[20px] border-[1px] border-w-green-200">
              <div className="w-full lg:w-[535px] py-[25px] text-center bg-w-green-100 border-[1px] border-w-green-200">
                <div className='w-full'>
                  <Image layout="responsive" src={SliceImage} alt='_sliceImage' />
                </div>
                <div className='pb-[23px] pt-[8px]'>
                  <p className="font-night text-outline text-w-green-300 text-[24px]">Make them stay, your way!</p>
                </div>
                <div className='w-full'>
                  <Image layout="responsive" src={SliceImage} alt='_sliceImage' />
                </div>
              </div>
              <div className='flex flex-col gap-y-[35px] lg:flex-row pt-[40px] lg:pt-[25px] pb-[61px] gap-x-[70px] px-[35px] lg:pl-[90px] lg:pr-[60px] justify-center items-center'>
                <div>
                  <div className='w-[153px] h-[195px] small-screen:w-[185px] small-screen:h-[235px] md:w-[241px] md:h-[308px]'>
                    <Image layout="responsive" objectFit='cover' src={SectionImage2} alt='_sectionimage' />
                  </div>
                </div>
                <div>
                  <div>
                    <p>Dengan program <strong>wellness-oriented</strong> Workay, bantu karyawan berkembang dan memaksimalkan potensinya.</p>
                  </div>
                  <div className='mt-4'>
                    <p>Kurangi turnover rate perusahaan,
                      dan tingkatkan loyalitas karyawan dengan memberikan benefit sesuai kebutuhan finansialnya.</p>
                  </div>
                  <div className='mt-4'>
                    <p><strong>[Not-so-fun fact]</strong> Karyawan yang memiliki stres finansial, 2X lebih sering mencari pekerjaan lain, di mana 76% lebih tertarik untuk bekerja di perusahaan yang mementingkan financial wellness karyawannya.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-[30px] lg:px-[150px] w-full mt-[50px]">
            <div className="flex flex-col items-center justify-center rounded-b-[20px] lg:rounded-bl-[0px] lg:rounded-r-[20px] border-[1px] border-w-green-200">
              <div className="w-full lg:w-[535px] py-[25px] text-center bg-w-green-100 border-[1px] border-w-green-200">
                <div className='w-full'>
                  <Image layout="responsive" src={SliceImage} alt='_sliceImage' />
                </div>
                <div className='pb-[23px] pt-[8px]'>
                  <p className="font-night text-outline text-w-green-300 text-[24px]">Financial stress, no more!</p>
                </div>
                <div className='w-full'>
                  <Image layout="responsive" src={SliceImage} alt='_sliceImage' />
                </div>
              </div>
              <div className='flex flex-col gap-y-[35px] lg:flex-row pt-[45px] pb-[61px] lg:gap-x-[25px] xl:gap-x-[36px] px-[35px] lg:pl-[90px] lg:pr-[42px] justify-center items-center'>
                <div>
                  <div className='lg:hidden w-[190px] h-[163px] small-screen:w-[275px] small-screen:h-[235px]  lg:w-[222px] lg:h-[180px] xl:w-[292px] xl:h-[250px]'>
                    <Image layout="responsive" objectFit='cover' src={SectionImage3} alt='_sectionimage' />
                  </div>
                </div>
                <div>
                  <div>
                    <p>Banyak karyawan yang masih menghabiskan waktu kerjanya untuk mengatasi masalah finansial. <strong>Stres finansial</strong> dapat mempengaruhi kesehatan mental, rumah tangga, dan tingkat kehadiran kerja, lho!</p>
                  </div>
                  <div className='grid lg:grid-cols-3 lg:gap-x-[35px] gap-y-[15px] pt-[25px] grid-cols-1 justify-between items-center lg:items-start lg:h-full'>
                    <div className='pb-[25px] gap-x-[11px] md:gap-x-[20px] lg:gap-y-[17px] flex flex-row lg:flex-col items-center lg:h-full bg-w-green-100 overflow-hidden pt-[35px] px-[15px] md:px-[20px] rounded-[20px]'>
                      <div className='rounded-full flex flex-row items-center justify-center bg-w-green-200 p-5 w-[62px] h-[62px] lg:w-[106px] lg:h-[106px] '>
                        <span className='flex flex-row items-center justify-center'>
                          <p className='text-white font-bold text-[24px] lg:text-[40px]'>72</p>
                          <p className='text-white font-bold ml-1 mt-2 text-[13px] lg:text-[20px]'>%</p>
                        </span>
                      </div>
                      <p className='lg:text-center'>Karyawan belum mempunyai dana darurat</p>
                    </div>
                    <div className='pb-[25px] gap-x-[11px] md:gap-x-[20px] lg:gap-y-[17px] flex flex-row lg:flex-col items-center lg:h-full bg-w-green-100 overflow-hidden pt-[35px] px-[15px] md:px-[20px] rounded-[20px]'>
                      <div className='rounded-full flex flex-row items-center justify-center bg-w-green-200 p-5 w-[62px] h-[62px] lg:w-[106px] lg:h-[106px] '>
                        <span className='flex flex-row items-center justify-center'>
                          <p className='text-white font-bold text-[24px] lg:text-[40px]'>73</p>
                          <p className='text-white font-bold ml-1 mt-2 text-[13px] lg:text-[20px]'>%</p>
                        </span>
                      </div>
                      <p className='lg:text-center'>Karyawan bilang gaji mereka habis sebelum gajian berikutnya</p>
                    </div>
                    <div className='pb-[25px] gap-x-[11px] md:gap-x-[20px] lg:gap-y-[17px] flex flex-row lg:flex-col items-center lg:h-full bg-w-green-100 overflow-hidden pt-[35px] px-[15px] md:px-[20px] rounded-[20px]'>
                      <div className='rounded-full flex flex-row items-center justify-center bg-w-green-200 p-5 w-[62px] h-[62px] lg:w-[106px] lg:h-[106px] '>
                        <span className='flex flex-row items-center justify-center'>
                          <p className='text-white font-bold text-[24px] lg:text-[40px]'>44</p>
                          <p className='text-white font-bold ml-1 mt-2 text-[13px] lg:text-[20px]'>%</p>
                        </span>
                      </div>
                      <p className='lg:text-center'>Karyawan mencari pekerjaan tambahan untuk memenuhi kebutuhan bulanan</p>
                    </div>
                  </div>
                  <div className='mt-[35px]'>
                    <p>Menanamkan <strong>literasi keuangan</strong> pada karyawan dapat dimulai dengan dukungan penuh dari perusahaan Anda. Begini caranya!</p>
                  </div>
                </div>
                <div>
                  <div className='hidden lg:block lg:w-[222px] lg:h-[180px] xl:w-[292px] xl:h-[250px]'>
                    <Image layout="responsive" objectFit='cover' src={SectionImage3} alt='_sectionimage' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionTwo;