import Image from 'next/image';
import { useRef, useState } from 'react';
import { SwiperSlide, Swiper } from 'swiper/react';
import FiturImage1 from '../../assets/images/fitur-1.png'
import FiturImage2 from '../../assets/images/fitur-2.png'
import NextIcon from '../../assets/icons/next-icon.svg'
import PrevIcon from '../../assets/icons/prev-icon.svg'

import 'swiper/css';
import 'swiper/css/pagination';

import { Autoplay, Pagination } from "swiper";

const SectionThree = () => {
  const swiperRef = useRef();


  return (
    <div className="mt-[110px] lg:mt-[160px] bg-w-green-100">
      <div className="container mx-auto flex flex-col items-center justify-center">
        <div className="text-center pt-[70px] px-[30px] md:px-[0px]">
          <div>
            <p className="text-[48px] font-bold text-w-green-200">Fitur Produk</p>
          </div>
          <div className=" text-[24px] font-night text-outline-2">
            <h3>Wellness-oriented Workay</h3>
          </div>
        </div>
        <div className='w-full mt-[80px]'>
          <Swiper onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }} spaceBetween={50} pagination={{ clickable: true }} modules={[Pagination, Autoplay]} className="mySwiper2 gap-5">
            <SwiperSlide>
              <div className="flex md:px-[150px] pb-[132px] lg:pb-[100px] flex-col lg:flex-row gap-x-[56px] justify-center items-center">
                <div className='w-[400px] h-auto small-screen:w-[445px] small-screen:h-[420px]'>
                  <Image layout='responsive' objectFit='cover' src={FiturImage1} alt='fitur_image' />
                </div>
                <div className='flex px-[35px] md:px-[0px] flex-col lg:w-[400px] xl:w-[325px]'>
                  <div className='w-full py-2 bg-white border-[2px] text-w-green-200 border-w-green-200 rounded-[20px] text-center'>
                    <p className=' font-bold'>Advanced Salary</p>
                  </div>
                  <div className='mt-[25px]'>
                    <p>Karyawan dapat mengakses gaji bulanan lebih awal, kapan pun dan di mana pun. Bantu karyawan:</p>
                    <ul className='list-disc list-inside ml-10 mt-5 list-section'>
                      <li>Meredakan masalah stres finansial yang dimiliki</li>
                      <li>Mengindari mengambil alternatif pinjaman berbunga tinggi</li>
                      <li>Menjaga cash flow perusahaan</li>
                      <li>Mempertahankan karyawan terbaik</li>
                    </ul>
                    <p className='mt-5'><strong>*Penarikan Advance Salary</strong> maximum 80% dari gaji bulanan.</p>
                  </div>
                </div>
                <div className='hidden lg:block hover:scale-110 active:scale-100 ml-[50px] xl:ml-[120px]' onClick={() => swiperRef.current.slideNext()} >
                  <Image layout='fixed' src={NextIcon} alt='next-icon' />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex md:px-[150px] pb-[132px] lg:pb-[100px] flex-col lg:flex-row gap-x-[56px] justify-start items-center">
                <div className='hidden lg:block hover:scale-110 active:scale-100 mr-[50px] xl:mr-[100px]' onClick={() => swiperRef.current.slidePrev()} >
                  <Image layout='fixed' src={PrevIcon} alt='next-icon' />
                </div>
                <div className='w-[400px] h-auto mb-5 small-screen:w-[445px] small-screen:h-[420px]'>
                  <Image layout='responsive' objectFit='cover' src={FiturImage2} alt='fitur_image' />
                </div>
                <div className='flex px-[35px] md:px-[0px] flex-col lg:w-[400px] xl:w-[325px]'>
                  <div className='w-full py-2 bg-white border-[2px] text-w-green-200 border-w-green-200 rounded-[20px] text-center'>
                    <p className=' font-bold'>Employee Rewards</p>
                  </div>
                  <div className='mt-[25px]'>
                    <p>WERK (Warung Employee Reward Karyawan)
agar karyawan terus onboard, jangan sampai mereka bored! Bantu karyawan mengelola keuangan dengan deal-deal menarik untuk memenuhi kebutuhan mereka.</p>
                    <div className='mt-5'>
                      <p className='font-bold'>Benefit untuk perusahaan:</p>
                      <p>Mudah mengatur budget employee wellness setiap karyawan yang digunakan untuk meningkatkan produktivitas, kesehatan, dan kebahagiaan karyawan.</p>
                    </div>
                    <div className='mt-5'>
                      <p className='font-bold'>Benefit untuk karyawan:</p>
                      <p>Nikmati diskon khusus dari berbagai merchant Workay, seperti voucher restoran, keanggotaan gym, training, paket data, hingga kebutuhan sehari-hari.</p>
                    </div>
                  </div>
                </div>

              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default SectionThree;